function scrollToElement(element) {
    if (!element) {
        return;
    }

    let top = element.offsetTop;

    for (
        let offsetParent = element.offsetParent;
        offsetParent;
        offsetParent = offsetParent.offsetParent
    ) {
        top += offsetParent.offsetTop;
    }

    Array.from(document.getElementsByClassName('push-top')).forEach(pusher => {
        if (window.getComputedStyle(pusher).display !== 'none') {
            top -= pusher.offsetHeight;
        }
    });

    const isSmoothScrollSupported =
        'scrollBehavior' in document.documentElement.style;
    if (isSmoothScrollSupported) {
        window.scrollTo({ top, left: 0, behavior: 'smooth' });
    } else {
        window.scrollTo(0, top);
    }
}

export default scrollToElement;
